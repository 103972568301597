<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Buscar movimiento bancario</h1>

    <div class="table-header table-header-border">
      Cantidad de movimientos bancarios: <b>{{ filteredMovements.length }}</b>
    </div>
    <b-table
      pagination-position="both"
      :data="filteredMovements"
      :striped="true"
      :bordered="true"
      :hoverable="true"
      ref="SelectMovement"
      paginated
      :current-page="currentPage"
      :per-page="perPage"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort-direction="asc"
      class="purchase-order-table"
      scrollable
      @click="(row) => selectMovement(row)"
    >
      <template #empty>
        <div class="has-text-centered">No hay movimientos bancarios</div>
      </template>

      <template slot-scope="props">
        <b-table-column
          label="Referencia"
          field="REFERENCIA"
          sortable
          searchable
        >
          <span v-if="!checkIfValid(props.row.REFERENCIA)">{{
            "Sin información"
          }}</span>
          <span>{{ props.row.REFERENCIA }}</span>
        </b-table-column>

        <b-table-column
          label="Fecha"
          field="FECHA_FORMATO"
          sortable
          searchable
          :custom-sort="sortByDate"
        >
          <span v-if="!checkIfValid(props.row.FECHA_FORMATO)">{{
            "Sin información"
          }}</span>
          <span>{{ props.row.FECHA_FORMATO }}</span>
        </b-table-column>

        <b-table-column label="Cliente" field="CLIENTE_FOR" sortable searchable>
          <span v-if="!checkIfValid(props.row.CLIENTE_FOR)">{{
            "Sin información"
          }}</span>
          <span v-if="checkIfValid(props.row.CLIENTE_FOR)">{{
            props.row.CLIENTE_FOR
          }}</span>
        </b-table-column>

        <b-table-column label="Proveedor" field="PROV_FOR" sortable searchable>
          <span v-if="!checkIfValid(props.row.PROV_FOR)">{{
            "Sin información"
          }}</span>
          <span v-if="checkIfValid(props.row.PROV_FOR)">{{
            props.row.PROV_FOR
          }}</span>
        </b-table-column>

        <b-table-column
          label="Consolidado"
          field="CONSOLIDADO_FOR"
          sortable
          searchable
        >
          <span v-if="!checkIfValid(props.row.CONSOLIDADO_FOR)">{{
            "Sin información"
          }}</span>
          <span>{{ props.row.CONSOLIDADO_FOR }}</span>
        </b-table-column>

        <b-table-column
          label="Tipo de movimiento"
          field="TIPOAFECTACION_FOR"
          sortable
          searchable
        >
          <span v-if="!checkIfValid(props.row.TIPOAFECTACION_FOR)">{{
            "Sin información"
          }}</span>
          <span>{{ props.row.TIPOAFECTACION_FOR }}</span>
        </b-table-column>

        <b-table-column
          label="Importe"
          field="IMPORTE"
          sortable
          searchable
          :custom-sort="sortByPaymentQuantity"
        >
          <span v-if="!checkIfValid(props.row.IMPORTE)">{{
            "Sin información"
          }}</span>
          <span v-if="checkIfValid(props.row.IMPORTE)">{{
            props.row.IMPORTE | money("MXN", 2)
          }}</span>
        </b-table-column>

        <b-table-column
          label="Restante a utilizar"
          field="RESTA"
          sortable
          searchable
          :custom-sort="sortByMisingQuantity"
        >
          <span v-if="!checkIfValid(props.row.RESTA)">{{
            "Sin información"
          }}</span>
          <span v-if="checkIfValid(props.row.RESTA)">{{
            props.row.RESTA | money("MXN", 2)
          }}</span>
        </b-table-column>

        <b-table-column label="Status" field="STATUS_FOR" sortable searchable>
          <span v-if="!checkIfValid(props.row.STATUS_FOR)">{{
            "Sin información"
          }}</span>
          <span>{{ props.row.STATUS_FOR }}</span>
        </b-table-column>

        <b-table-column
          label="Descripción"
          field="DESCRIPCION"
          sortable
          searchable
        >
          <span v-if="!checkIfValid(props.row.DESCRIPCION)">{{
            "Sin información"
          }}</span>
          <span v-if="props.row.DESCRIPCION">{{ props.row.DESCRIPCION }}</span>
        </b-table-column>

        <b-table-column
          label="Tipo de conexión de movimiento"
          field="AUTOMATICO_FOR"
          sortable
          searchable
        >
          <span v-if="!checkIfValid(props.row.AUTOMATICO_FOR)">{{
            "Sin información"
          }}</span>
          <span>{{ props.row.AUTOMATICO_FOR }}</span>
        </b-table-column>

        <b-table-column
          label="Se muestra en tesorería"
          field="MOSTRAR_TESORERIA_FOR"
          sortable
          searchable
        >
          <span v-if="!checkIfValid(props.row.MOSTRAR_TESORERIA_FOR)">{{
            "Sin información"
          }}</span>
          <span>{{ props.row.MOSTRAR_TESORERIA_FOR }}</span>
        </b-table-column>
      </template>
    </b-table>

    <div class="add-global-controls">
      <b-button type="is-danger" @click="closeModal()">Regresar</b-button>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../../../event-bus";
import moment from "moment";
// @ is an alias to /src
export default {
  name: "SelectMovement",
  props: ["bankAccount", "accountType", "paymentInformation"],
  components: {},
  data() {
    return {
      currentPage: 1,
      perPage: 50,
      columns: [
        {
          field: "CLAVE_ART",
          label: "Clave de articulo",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DESCRIPCION",
          label: "Descripción",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
    };
  },
  created() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async selectMovement(movement) {
      EventBus.$emit("searchMovementSelected", {
        movement: movement,
      });

      this.closeModal();
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA).getTime() || -Infinity) -
          (new Date(a.FECHA).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA).getTime() || -Infinity) -
          (new Date(b.FECHA).getTime() || -Infinity)
        );
      }
    },
    sortByMisingQuantity(a, b, isAsc) {
      if (isAsc) {
        return Number(a.RESTA) - Number(b.RESTA);
      } else {
        return Number(b.RESTA) - Number(a.RESTA);
      }
    },
    sortByPaymentQuantity(a, b, isAsc) {
      if (isAsc) {
        return Number(a.IMPORTE) - Number(b.IMPORTE);
      } else {
        return Number(b.IMPORTE) - Number(a.IMPORTE);
      }
    },
  },
  computed: {
    bankAccounts() {
      return this.$store.getters.BANKACCOUNTS;
    },
    filteredMovements() {
      for (const singleBankAccount of this.bankAccounts) {
        if (this.bankAccount === singleBankAccount._id) {
          const bankAccountmovements = singleBankAccount.MOVIMIENTOS.map(
            (singleBankMovement) => {
              let singleBankMovementWithProps = { ...singleBankMovement };
              singleBankMovementWithProps.CLIENTE_FOR = "";
              if (singleBankMovementWithProps.CLAVE_CLIENTE) {
                singleBankMovementWithProps.CLIENTE_FOR =
                  singleBankMovementWithProps.CLAVE_CLIENTE.NOMBRE_EMPRESA;
              }
              singleBankMovementWithProps.PROV_FOR = "";
              if (singleBankMovementWithProps.CLAVE_PRV) {
                singleBankMovementWithProps.PROV_FOR =
                  singleBankMovementWithProps.CLAVE_PRV.NOMBRE_PRV;
              }
              singleBankMovementWithProps.FECHA_FORMATO = moment(
                singleBankMovementWithProps.FECHA
              ).format("DD-MM-YYYY HH:mm");
              singleBankMovementWithProps.CONSOLIDADO_FOR =
                singleBankMovement.CONSOLIDADO === "S" ? "Si" : "No";
              singleBankMovementWithProps.TIPOAFECTACION_FOR =
                singleBankMovement.TIPOAFECTACION === "RE"
                  ? "Egreso"
                  : "Ingreso";
              singleBankMovementWithProps.IMPORTE = singleBankMovement.IMPORTE
                ? singleBankMovement.IMPORTE.toString()
                : 0;
              singleBankMovementWithProps.MOSTRAR_TESORERIA_FOR =
                singleBankMovement.MOSTRAR_TESORERIA === "S" ? "Si" : "No";
              singleBankMovementWithProps.AUTOMATICO_FOR =
                singleBankMovement.AUTOMATICO === "S" ? "Automática" : "Manual";

              singleBankMovementWithProps.STATUS_FOR = "Pendiente";
              if (
                singleBankMovement.STATUS === "CO" &&
                singleBankMovement.RESTA > 1
              ) {
                singleBankMovementWithProps.STATUS_FOR = "Confirmado";
              }
              if (
                singleBankMovement.STATUS === "PE" &&
                singleBankMovement.RESTA > 1
              ) {
                singleBankMovementWithProps.STATUS_FOR = "Pendiente";
              }
              if (
                singleBankMovement.STATUS === "CA" &&
                singleBankMovement.RESTA > 1
              ) {
                singleBankMovementWithProps.STATUS_FOR = "Cancelado";
              }
              if (
                singleBankMovement.STATUS === "CA" &&
                singleBankMovement.RESTA < 1
              ) {
                singleBankMovementWithProps.STATUS_FOR = "Aplicado";
              }

              singleBankMovementWithProps.RESTA = String(
                singleBankMovementWithProps.RESTA
              );
              return singleBankMovementWithProps;
            }
          );
          if (this.accountType === "CC") {
            return bankAccountmovements.filter((singleMovement) => {
              if (
                singleMovement.STATUS === "CA" ||
                singleMovement.MOSTRAR_TESORERIA === "N"
              ) {
                return false;
              }

              if (
                !singleMovement.CLAVE_CLIENTE &&
                !singleMovement.CLAVE_PRV &&
                singleMovement.TIPOAFECTACION === "SU" &&
                singleMovement.RESTA > 1
              ) {
                return true;
              }

              if (
                singleMovement.CLAVE_CLIENTE &&
                singleMovement.CLAVE_CLIENTE._id ===
                  this.paymentInformation.CLAVE_CLIENTE &&
                singleMovement.TIPOAFECTACION === "SU" &&
                singleMovement.RESTA > 1
              ) {
                return true;
              }

              return false;
            });
          } else if (this.accountType === "CP") {
            return bankAccountmovements.filter((singleMovement) => {
              if (
                singleMovement.STATUS === "CA" ||
                singleMovement.MOSTRAR_TESORERIA === "N"
              ) {
                return false;
              }

              if (
                !singleMovement.CLAVE_CLIENTE &&
                !singleMovement.CLAVE_PRV &&
                singleMovement.TIPOAFECTACION === "RE" &&
                singleMovement.RESTA > 1
              ) {
                return true;
              }

              if (
                singleMovement.CLAVE_PRV &&
                singleMovement.CLAVE_PRV._id ===
                  this.paymentInformation.CLAVE_PRV &&
                singleMovement.TIPOAFECTACION === "RE" &&
                singleMovement.RESTA > 1
              ) {
                return true;
              }

              return false;
            });
          }
        }
      }
      return [];
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
